import { useTrackFormSubmit } from '~/lib/mixpanel/mixpanel.hooks';
import { createMarkup } from '~/utils/functions';
import { dataTypes } from '~/utils/propTypes';

import { ContactForm } from '../Forms/ContactForm/ContactForm';

import classes from './Contact.module.scss';

const Contact = ({ content }) => {
  const formContainerRef = useTrackFormSubmit('contact');

  return (
    <section className={classes.Contact}>
      <div className={classes.Contact__inner}>
        <div className={classes.Contact__infoContainer}>
          <div
            className={classes.contactList} // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={createMarkup(content.contactSection, true)}
          />
        </div>
        <div className={classes.Contact__formContainer} ref={formContainerRef}>
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

Contact.propTypes = {
  content: dataTypes.contact.props,
};
Contact.defaultProps = {
  content: dataTypes.contact.default,
};
export default Contact;
