import classnames from 'classnames';

import { trackLink } from '~/lib/mixpanel/mixpanel';

import { prepareURL } from '../../utils/functions';
import { dataTypes } from '../../utils/propTypes';
import Link from '../Link/Link';

import classes from './MobileContentMenu.module.scss';

const MobileContentMenu = ({ mobileMenuItems, closeMobileMenu }) => {
  const renderMobileMenuItem = (menuItem) => {
    const linkURL = menuItem?.url || menuItem?.page?.slug || '/';

    const linkClassnames = [
      classnames(classes.MobileContentMenu__menuItemAnchor, menuItem.elementClassname),
    ].join(' ');

    return (
      <li
        className={classes.MobileContentMenu__menuListElement}
        key={`renderMobileMenuItem-${menuItem.id}`}
      >
        <Link
          href={prepareURL(linkURL)}
          className={linkClassnames}
          onClick={(mouseEvent) => {
            const mixpanelEvent = menuItem.event;
            if (mixpanelEvent) {
              trackLink(mouseEvent, mixpanelEvent.name, {
                placement: mixpanelEvent.placement,
                ...(mixpanelEvent.name === 'featurebase_site_clicked'
                  ? { link_text: menuItem.label.toLowerCase() }
                  : { button_text: menuItem.label, button_id: menuItem.elementId }),
              });
            }
            closeMobileMenu(mouseEvent);
          }}
          onKeyDown={closeMobileMenu}
          role="button"
          tabIndex="0"
          id={menuItem.elementId}
          native={menuItem?.page?.useNativeLinks}
        >
          <span className={classes.MobileContentMenu__menuItemLabel}>
            {menuItem.label}{' '}
            {menuItem.badge && (
              <span className={classes.MobileMenuItemBadge}>{menuItem.badge}</span>
            )}
          </span>
        </Link>
      </li>
    );
  };
  return (
    <ul className={classes.MobileContentMenu__menu}>
      {mobileMenuItems?.list?.map(renderMobileMenuItem) || null}
    </ul>
  );
};

MobileContentMenu.propTypes = {
  mobileMenuItems: dataTypes.mobileMenuData.props,
  closeMobileMenu: dataTypes.closeMobileMenu.props,
};

MobileContentMenu.defaultProps = {
  mobileMenuItems: dataTypes.mobileMenuData.default,
  closeMobileMenu: dataTypes.closeMobileMenu.default,
};

export default MobileContentMenu;
