import classnames from 'classnames';
import * as R from 'ramda';

import authorsGroup from '../../../public/assets/icons/authorsGroup.svg';
import { TYPE_OF_AUTHORS } from '../../../utils/constants';
import { getUploadAssetsURL } from '../../../utils/functions';
import { dataTypes } from '../../../utils/propTypes';
import Image from '../../Image/Image';

import { AuthorCard } from './AuthorCard';
import classes from './Authors.module.scss';

const Authors = ({ data, type, noBottomBorder = false }) => {
  const elementClassNames = [
    classnames(classes.Authors, {
      [classes[TYPE_OF_AUTHORS.STANDARD]]: type === TYPE_OF_AUTHORS.STANDARD,
      [classes[TYPE_OF_AUTHORS.EXTENDED]]: type === TYPE_OF_AUTHORS.EXTENDED,
      [classes[TYPE_OF_AUTHORS.DETAILED]]: type === TYPE_OF_AUTHORS.DETAILED,
      [classes.noBorder]: noBottomBorder,
    }),
  ].join(' ');

  const imageSizes = {
    [TYPE_OF_AUTHORS.EXTENDED]: 20,
    [TYPE_OF_AUTHORS.DETAILED]: 90,
  };

  const renderStandard = () => (
    <p className={classes.Authors__standard}>{data.map(({ name }) => name).join(', ')}</p>
  );

  const renderExpanded = () => (
    <div className={elementClassNames}>
      {type === TYPE_OF_AUTHORS.EXTENDED && data.length > 1 && (
        <>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={authorsGroup.src}
            alt="authors group"
            width={imageSizes[type]}
            height={imageSizes[type]}
          />
        </>
      )}
      {type === TYPE_OF_AUTHORS.EXTENDED && data.length > 1 ? (
        <p className={classes.Authors__group}>{data.map(({ name }) => name).join(', ')}</p>
      ) : (
        data.map((author) => {
          const { id, name, image } = author;

          if (type === TYPE_OF_AUTHORS.DETAILED) {
            return <AuthorCard author={author} key={`RenderBlogAuthor-${id}`} />;
          }

          return (
            <div className={classes.Authors__author} key={`RenderBlogAuthor-${id}`}>
              <Image
                className={classes.Authors__image}
                src={getUploadAssetsURL(image.url)}
                alt={image.alternativeText}
                width={imageSizes[type]}
                height={imageSizes[type]}
                objectFit="cover"
              />
              <p className={classes.Authors__name}>{name}</p>
            </div>
          );
        })
      )}
    </div>
  );

  const renderAuthorSection = R.cond([
    [R.equals(TYPE_OF_AUTHORS.STANDARD), renderStandard],
    [R.equals(TYPE_OF_AUTHORS.EXTENDED), renderExpanded],
    [R.equals(TYPE_OF_AUTHORS.DETAILED), renderExpanded],
    [R.T, R.always(null)],
  ]);

  return renderAuthorSection(type);
};

Authors.propTypes = {
  data: dataTypes.postsAuthors.props,
  type: dataTypes.authorsSectionType.props,
};

Authors.defaultProps = {
  data: dataTypes.postsAuthors.default,
  type: dataTypes.authorsSectionType.default,
};

export default Authors;
