import classNames from 'classnames';
import { Formik, Form } from 'formik';

import type { ContactFormValues } from './ContactForm.schema';
import type { FormikHelpers } from 'formik';

import { randomId } from '~/utils/randomId';

import { FormInput } from '../FormInput/FormInput';

import classes from './ContactForm.module.scss';
import { contactFormSchema } from './ContactForm.schema';

const handleSubmitAction = async (
  { message, name, email }: ContactFormValues,
  actions: FormikHelpers<ContactFormValues>,
) => {
  actions.setStatus('pending');

  const response = await fetch('https://app.bugbug.io/rest/tickets/', {
    method: 'POST',
    body: JSON.stringify({
      subject: `BugBug contact form (id: ${randomId()})`,
      type: 'Contact form',
      description: message.replace(/\n/g, '<br>'),
      name,
      email,
      status: 2,
      priority: 1,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const error = response.status !== 201;

  if (error) {
    actions.setStatus('error');
  } else {
    actions.setStatus('success');
    const messageTimeout = 3500;

    setTimeout(() => {
      actions.setStatus('idle');
      actions.resetForm();
    }, messageTimeout);
  }
};

export const ContactForm = () => (
  <Formik
    initialValues={{
      name: '',
      email: '',
      message: '',
    }}
    validationSchema={contactFormSchema}
    onSubmit={handleSubmitAction}
  >
    {({ status }) => (
      <Form>
        <div className={classes.FieldsContainer}>
          <FormInput name="name" label="Your name" />
          <FormInput name="email" label="Email" />
          <FormInput name="message" label="How can we help?" as="textarea" rows={16} />
        </div>
        <p className={classes.PrivacyPolicyInfo}>
          By clicking &quot;Send Message&quot; you agree to our terms that you have read in our{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/privacy-policy/"
            className={classes.PrivacyPolicyLink}
          >
            Privacy Policy
          </a>{' '}
          page.
        </p>
        {status !== 'success' && (
          <button className={classes.SubmitButton} type="submit" disabled={status === 'pending'}>
            <div className={classes.SubmitButtonSpinner} hidden={status !== 'pending'} />
            <span>Send Message</span>
          </button>
        )}
        {status === 'success' && (
          <div className={classNames(classes.ResponseMessage, classes.SuccessMessage)}>
            Message was send.
          </div>
        )}
        {status === 'errror' && (
          <div className={classNames(classes.ResponseMessage, classes.ErrorMessage)}>
            Message was not send by server error. Please try again later.
          </div>
        )}
      </Form>
    )}
  </Formik>
);
