import Image from 'next/image';
import Link from 'next/link';

import type { Author } from '~/types/models';

import { createMarkup, getUploadAssetsURL } from '~/utils/functions';
import { getAuthorUrl } from '~/utils/urls';

import classes from './AuthorCard.module.scss';

import LinkedInIcon from '~/public/assets/icons/linkedIn.svg?react';
import TwitterIcon from '~/public/assets/icons/twitter.svg?react';

interface AuthorCardProps {
  author: Author;
}

export const AuthorCard = ({ author }: AuthorCardProps) => {
  const { image, name, role, description, linkedInProfileUrl, twitterProfileUrl } = author;
  return (
    <div className={classes.Container}>
      {image && (
        <Image
          className={classes.AuthorPhoto}
          src={getUploadAssetsURL(image.url)}
          alt={image.alternativeText}
          width={120}
          height={120}
          objectFit="cover"
        />
      )}
      <div>
        <Link className={classes.AuthorName} href={getAuthorUrl(author)}>
          {name}
        </Link>
        <div className={classes.AuthorDetails}>
          {role && <p className={classes.AuthorRole}>{role}</p>}
          <div className={classes.AuthorLinks}>
            {linkedInProfileUrl && (
              <Link href={linkedInProfileUrl} target="_blank">
                <LinkedInIcon className={classes.AuthorSocialLink} />
              </Link>
            )}
            {twitterProfileUrl && (
              <Link href={twitterProfileUrl} target="_blank">
                <TwitterIcon className={classes.AuthorSocialLink} />
              </Link>
            )}
          </div>
        </div>
        <div
          className={classes.AuthorDescription}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(description, false, true)}
        />
      </div>
    </div>
  );
};
