import classnames from 'classnames';
import { useRouter } from 'next/router';
import * as R from 'ramda';
import { useEffect, useState, useRef } from 'react';

import { trackLink } from '~/lib/mixpanel/mixpanel';
import { getUrlProps, isUnknownMouseClick, prepareURL } from '~/utils/functions';

import Link from '../Link/Link';

import classes from './ContentMenu.module.scss';

const COMPONENT_TYPE = {
  ROOT_MENU_ITEM: 'menu-components.menu-item',
  DROPDOWN_MENU_ITEMS: 'menu-components.dropdown-menu-item',
};

const ContentMenu = ({ mainMenuItems }) => {
  const dropdownRef = useRef([]);
  const [dropdown, setDropdown] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const handleDropdownClick = (idx) => (event) => {
    event.stopPropagation();
    setDropdown(dropdown === idx ? null : idx);
  };

  useEffect(() => {
    const handleWindowClick = () => setDropdown(null);

    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, []);

  const router = useRouter();

  const trackRedirect = (mouseEvent, singleLink) => {
    if (isUnknownMouseClick(mouseEvent)) return;

    const mixpanelEvent = singleLink.event;
    if (mixpanelEvent) {
      trackLink(mouseEvent, mixpanelEvent.name, {
        placement: mixpanelEvent.placement,
        ...(mixpanelEvent.name === 'featurebase_site_clicked'
          ? { link_text: singleLink.label.toLowerCase() }
          : { button_text: singleLink.label, button_id: singleLink.elementId }),
      });
    }
  };

  const renderRootMenuItem = (menuItem) => {
    const linkURL = menuItem?.url || menuItem?.page?.slug || '/';

    const linkClassnames = [
      classnames(classes.ContentMenu__rootMenuItemAnchor, menuItem.elementClassname),
    ].join(' ');

    return (
      <li
        className={classes.ContentMenu__rootMenuListElement}
        key={`renderRootMenuItem-${menuItem.id}`}
      >
        <Link
          href={prepareURL(linkURL)}
          className={linkClassnames}
          id={menuItem.elementId}
          native={menuItem?.page?.useNativeLinks}
          onClick={(mouseEvent) => trackRedirect(mouseEvent, menuItem)}
          onAuxClick={(mouseEvent) => trackRedirect(mouseEvent, menuItem)}
          {...getUrlProps({ url: linkURL, openInNewTab: menuItem.openInNewTab })}
        >
          <span className={classes.ContentMenu__rootMenuItemLabel}>{menuItem.label}</span>
          {router.asPath.includes(prepareURL(linkURL)) && (
            <div className={classes.ContentMenu__rootMenuItemActiveBorder} />
          )}
        </Link>
      </li>
    );
  };

  const renderDropdownMenuContent = (singleLink) => {
    const linkURL = singleLink?.url || singleLink?.page?.slug || '/';

    return (
      <li
        className={classes.ContentMenu__nestedMenuListElement}
        key={`renderDropdownMenuContent-${singleLink.id}`}
      >
        <Link
          href={prepareURL(linkURL)}
          className={classes.ContentMenu__nestedMenuItemAnchor}
          id={singleLink.elementId}
          native={
            singleLink?.page?.useNativeLinks ||
            prepareURL(linkURL).includes('xpath-selector-builder')
          }
          onClick={(event) => trackRedirect(event, singleLink)}
          onAuxClick={(event) => trackRedirect(event, singleLink)}
          {...getUrlProps({ url: linkURL, openInNewTab: singleLink.openInNewTab })}
        >
          <span className={classes.ContentMenu__nestedMenuItemLabel}>{singleLink.label}</span>
          {singleLink.badge && <span className={classes.MenuItemBadge}>{singleLink.badge}</span>}
        </Link>
      </li>
    );
  };

  const renderDropdownMenu = (menuItem) => (
    <li className={classes.ContentMenu__rootMenuListElement} key={menuItem.id}>
      <button
        key={menuItem.id}
        className={classes.ContentMenu__rootMenuItemAnchor}
        onClick={handleDropdownClick(menuItem.id)}
        // eslint-disable-next-line no-return-assign
        ref={(el) => (dropdownRef.current[menuItem.id] = el)}
        aria-expanded={dropdown === menuItem.id ? 'true' : 'false'}
        type="button"
      >
        <span className={classes.ContentMenu__rootMenuItemLabel}>{menuItem.label}</span>
        <span className={classes.ContentMenu__rootMenuItemChevron} />
      </button>
      {menuItem.dropdownLinks && (
        <ul className={classes.ContentMenu__nestedMenu}>
          {menuItem.dropdownLinks.map(renderDropdownMenuContent)}
        </ul>
      )}
    </li>
  );

  const renderTopMenu = R.cond([
    [R.propEq('__component', COMPONENT_TYPE.ROOT_MENU_ITEM), renderRootMenuItem],
    [R.propEq('__component', COMPONENT_TYPE.DROPDOWN_MENU_ITEMS), renderDropdownMenu],
    [R.T, R.always(null)],
  ]);

  return (
    <ul className={classes.ContentMenu__rootMenu}>
      {mainMenuItems?.list ? mainMenuItems?.list.map(renderTopMenu) : null}
    </ul>
  );
};

export default ContentMenu;
