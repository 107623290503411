import * as yup from 'yup';

export const contactFormSchema = yup.object({
  name: yup.string().required('This field can not be empty.'),
  email: yup
    .string()
    .email('This field must be a valid email.')
    .required('This field can not be empty.'),
  message: yup.string().required('This field can not be empty.'),
});

export type ContactFormValues = yup.InferType<typeof contactFormSchema>;
