import { useField } from 'formik';

import classes from './FormInput.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  as?: 'input';
  name: string;
  label: string;
}

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  as: 'textarea';
  name: string;
  label: string;
}

type FormInputProps = InputProps | TextareaProps;
export const FormInput = (props: FormInputProps) => {
  const { name, label, as = 'input' } = props;
  const [field, meta] = useField(name);

  const Element = as === 'textarea' ? 'textarea' : 'input';

  return (
    <div className={classes.Container}>
      <label className={classes.Label} htmlFor={name}>
        {label}
        {/* @ts-expect-error - incorrect types inference */}
        <Element {...field} {...props} className={classes.Content} />
      </label>
      {meta.touched && meta.error && <div className={classes.Error}>{meta.error}</div>}
    </div>
  );
};
